import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import {createStyles, makeStyles, ThemeProvider} from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
} from "react-router-dom";
import {MasteringPage} from "./mastering/MasteringPage";
import { AuthProvider } from 'nate-react-api-helpers';
import {RequireLogin} from "./RequireLogin";
import {api} from "./API";
import { TSnackbarProvider } from 'nate-react-api-helpers/lib/components';
import {MasteringToken} from "./admin/MasteringToken";
import {PodcastListPage} from "./admin/podcasts/PodcastListPage";
import {PodcastDetailPage} from "./admin/podcasts/PodcastDetailPage";
import {PodcastPage} from "./admin/PodcastPage";

const theme = createMuiTheme({
    palette: {
        primary: {
            main: "#0083c7",
            contrastText: "#FFFFFF"
        }
    }
});
const useStyles = makeStyles(theme => createStyles({
    "@global": {
        "body": {
            height: "100%",
            width: "100%",
            display: "flex",
            position: "absolute",
            backgroundColor: "#0B0B0B",
        },
        "#root": {
            flex: 1,
            display: "flex",
            flexDirection: "column",
        }
    },
}));

function App() {
    useStyles();

  return (
        <ThemeProvider theme={theme}>
            <AuthProvider onPing={() => api.ping()}>
                <TSnackbarProvider>
                    <CssBaseline />
                    <Router>
                        <Switch>
                            <Route path="/admin/mastering-token">
                                <MasteringToken />
                                <RequireLogin />
                            </Route>
                            <Route path="/admin/podcasts/:id">
                                <PodcastDetailPage />
                                <RequireLogin />
                            </Route>
                            <Route path="/admin/podcasts">
                                <PodcastListPage />
                                <RequireLogin />
                            </Route>
                            <Route path="/admin">
                                <PodcastListPage />
                                <RequireLogin />
                            </Route>
                            <Route path="/podcast/:key">
                                <PodcastPage />
                            </Route>
                            <Route path="/master/:token">
                                <MasteringPage />
                            </Route>
                            <Route path="/mastering">
                                <MasteringPage />
                            </Route>
                            <Route path="/">
                                <Redirect to="/mastering" />
                            </Route>
                        </Switch>
                    </Router>
                </TSnackbarProvider>
            </AuthProvider>
        </ThemeProvider>
  );
}

export default App;
