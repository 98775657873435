import {AdminContainer} from "../AdminContainer";
import React from "react";
import {BCard} from "../../BCard";
import {useAsync2} from "nate-react-api-helpers";
import {api} from "../../API";
import {Button, createStyles, ListItem, makeStyles, Typography} from "@material-ui/core";
import moment from "moment";
import {grey} from "@material-ui/core/colors";
import { CreatePodcast } from "./CreatePodcast";
import {Link} from "react-router-dom";

const useStyles = makeStyles(theme => createStyles({
    listItem: {
        cursor: "pointer",
        width: "100%",
        padding: 6,
        paddingLeft: 15,
        paddingRight: 15,
        backgroundColor: "white",

        "&:hover": {
            backgroundColor: grey["300"] + " !important",
        }
    }
}));

export function PodcastListPage() {
    const list = useAsync2((input) => api.listPodcasts(), {}, []);
    const [showCreate, setShowCreate] = React.useState(false);
    const styles = useStyles();

    return (
        <AdminContainer>
            <div style={{flex: 1, display: "flex", alignItems: "stretch", justifyContent: "center", paddingTop: 20, paddingBottom: 20, overflow: "hidden"}}>
                <BCard style={{width: 600, padding: 0, flex: 1, display: "flex", flexDirection: "column", overflow: "hidden"}}>
                    <div style={{padding: 10, paddingLeft: 15, paddingRight: 15, display: "flex", justifyContent: "space-between"}}>
                        <Typography variant="h6">
                            Podcasts
                        </Typography>
                        <Button variant="contained" color="primary" onClick={() => {
                            setShowCreate(true);
                        }}>Create Podcast</Button>
                        {showCreate && <CreatePodcast onDone={() => setShowCreate(false)} />}
                    </div>
                    <div style={{flex: 1, overflow: "auto"}}>
                        {list.LoadingOrErrorElement}
                        {list.asList.map((v, index) => <Link style={{textDecoration: "none", color: "inherit"}} to={"/admin/podcasts/"+v.id}>
                            <ListItem className={styles.listItem} style={{
                                backgroundColor: index % 2 === 0 ? grey["200"] : "white",
                            }}>
                            <div>
                                <div style={{fontSize: "1rem"}}>{v.name}</div>
                                <div style={{display: "flex", fontSize: "0.8rem", color: grey["600"]}}>
                                    <div>Created {moment(v.createdAt).format("MMM YYYY")}</div>
                                    <div style={{marginLeft: 10}}>{v.masterCount} masters generated</div>
                                </div>
                            </div>
                        </ListItem></Link>)}
                        {!list.loading && list.asList.length === 0 && <div style={{padding: 20}}>No podcasts yet</div>}
                    </div>
                </BCard>
            </div>
        </AdminContainer>
    )
}