import React, {useEffect, useRef, useState} from "react";
import {api, CreateUploadResult} from "../API";
import {useAsyncAction} from "nate-react-api-helpers";
import {UploadInProgress} from "./UploadInProgress";
import {Download} from "./Download";
import {Failed} from "./Failed";

const progConfig = {
    createUpload: 10,
    uploadFile: 70,
    processFile: 30,
}

export function useUploader() {

    const [status, setStatus] = useState("Getting started");
    const [progress, setProgress] = useState(0);
    const [uploadInfo, setUploadInfo] = useState<CreateUploadResult>();
    const [fileUploadComplete, setFileUploadComplete] = useState(false);
    const [uploadComplete, setUploadComplete] = useState(false);
    const [done, setDone] = useState(false);
    const [downloadToken, setDownloadToken] = useState("");
    const [error, setError] = useState("");
    const [started, setStarted] = useState(false);
    const fileRef = useRef<File>();
    const tokenRef = useRef<string>();

    const upload = useAsyncAction(async (input: {file: File, token: string}) => {
        try {
            fileRef.current = input.file;
            tokenRef.current = input.token;
            const {file, token} = input;

            setStarted(true);

            let info: CreateUploadResult;

            if(!uploadInfo) {
                setProgress(0);
                setStatus("Creating upload");
                const createResult = await api.createUpload({token: token, fileName: input.file.name});

                // store info so we can rety if upload fails
                setUploadInfo(createResult);
                setProgress(progConfig.createUpload);
                info = createResult;
            } else {
                info = uploadInfo;
            }

            if(!fileUploadComplete) {
                setProgress(progConfig.createUpload);
                setStatus("Uploading");

                await api.upload(info.uploadUrl, file, prog => {
                    setProgress(prog.loaded / prog.total * progConfig.uploadFile + progConfig.createUpload);
                });

                setFileUploadComplete(true);
            }

            setProgress(progConfig.createUpload + progConfig.uploadFile);
            setStatus("Processing");

            await api.completeUpload({
                jobId: info.jobId,
                token: token,
            });

            setUploadComplete(true);
        } catch (e) {
            setError(e.message || "Network error. Please try again");
        }

    }, [uploadInfo, fileUploadComplete]);

    useEffect(() => {
        if(!uploadComplete) return;
        if(!uploadInfo) return;
        if(done) return;
        const token = tokenRef.current;
        if(!token) return;

        const interval = setInterval(async () => {
            const status = await api.getJobStatus({
                jobId: uploadInfo.jobId,
                token: token,
            });

            setProgress(progConfig.createUpload + progConfig.uploadFile + status.progress / 100 * progConfig.processFile);
            setStatus(status.status);

            if(status.done && status.downloadToken) {
                setDone(true);
                setError(status.errorMessage || "")
                setDownloadToken(status.downloadToken);
            }

        }, 5*1000);

        return () => clearInterval(interval);
    }, [uploadComplete, uploadInfo, done]);

    let element: JSX.Element | null = null;
    if(downloadToken && !error) {
        element = <Download token={downloadToken} />
    } else if(started && tokenRef.current) {
        element = <UploadInProgress retry={() => {
            if(fileRef.current && tokenRef.current) {
                upload.callback({
                    file: fileRef.current,
                    token: tokenRef.current,
                });
            }
        }} error={upload.error || error} progress={progress} status={status} jobId={uploadInfo?.jobId} dontCloseWindow={!uploadComplete} token={tokenRef.current || ""} />
    }

    return {
        upload: upload.callback,
        element: element,
    }
}