import React from "react";
import {Grid, Typography} from "@material-ui/core";
import { useUploader } from "./Uploader";
import {AccessCode} from "./AccessCode";
import {BCard} from "../BCard";
import { Container } from "../Container";

export function MasteringPage(props: {}) {

    const uploader = useUploader();

    return (
        <Container>
            <BCard>
                <Grid container direction="column" spacing={2}>
                    <Grid item style={{textAlign: "center"}}>
                        <Typography style={{marginBottom: 16}} variant="h4">Self-Serve Mastering</Typography>
                    </Grid>
                    <Grid item>
                        {uploader.element || <AccessCode onUpload={uploader.upload} />}
                    </Grid>
                </Grid>
            </BCard>
        </Container>
    )
}