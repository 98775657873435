import React from "react";
import {Button, Dialog, TextField} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import {useAsyncAction} from "nate-react-api-helpers";
import {api} from "../../API";
import {useHistory} from "react-router-dom";

export function CreatePodcast(props: {
    onDone(): void;
}) {

    const [name, setName] = React.useState("")
    const history = useHistory();

    const create = useAsyncAction(async (input) => {
        const result = await api.upsertPodcast(input);
        history.push("/admin/podcasts/" + result.id);
    }, [])

    return (
        <Dialog open fullWidth maxWidth="sm">
            <form onSubmit={e => {
                e.preventDefault()
                create.callback({name})
            }}>
                <DialogTitle>
                    Create Podcast
                </DialogTitle>
                <DialogContent>
                    <div style={{height: 8}} />
                    <TextField label="Name" variant="outlined" fullWidth value={name} onChange={e => setName(e.target.value)} />
                </DialogContent>
                <DialogActions>
                    {create.LoadingElement}
                    <Button onClick={props.onDone}>Cancel</Button>
                    <Button variant="contained" color="primary" type="submit" disabled={!name}>Create</Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}