import {Grid, LinearProgress, TextField} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {useAsyncAction} from "nate-react-api-helpers";
import {api} from "../API";

export function UploadInProgress(props: {
    jobId?: number;
    status: string;
    progress: number;
    token: string;
    dontCloseWindow: boolean;
    error?: string | null;
    retry: () => void;
}) {
    const [email, setEmail] = useState("");
    const [hasNotificationEmail, setHasNotificationEmail] = useState(false);
    const updateNotificationEmail = useAsyncAction(async (input) => {
        await api.setNotificationEmail(input);
        setHasNotificationEmail(true);
    }, []);

    useEffect(() => {
        if(!props.dontCloseWindow) return;

        // add nav prompt to prevent people from closing the window
        window.onbeforeunload = function() {
            return true;
        };

        // remove nav prompt
        return () => {
            window.onbeforeunload = null;
        }
    }, [props.dontCloseWindow]);

    if(props.error) {
        return (
            <div style={{padding: 16, paddingTop: 0}}>
                <Typography color="error" variant="body1" style={{marginBottom: 16}}>
                    Mastering Failed: {props.error}
                </Typography>
                <Button variant="outlined" color="primary" onClick={props.retry}>Try Again</Button>
            </div>
        )
    }

    return (
        <Grid container direction="column" spacing={2} style={{paddingLeft: 8, paddingRight: 8, maxWidth: 350}}>
          <Grid item>
              <LinearProgress value={props.progress} variant="determinate" style={{width: "100%"}} />
              <Typography variant="body2" color="textSecondary">
                  {props.status}...
              </Typography>
          </Grid>
            {hasNotificationEmail && <Grid item>
                <Typography variant="body2" color="textSecondary" style={{marginBottom: 8}}>
                    {props.dontCloseWindow ? <span>Don't close this window yet, your file is still uploading.</span> : <span>
                        It's safe to close this window now. We'll send a notification to {email} when your track is ready to download.
                    </span>}
                </Typography>
            </Grid>}
            {props.jobId && !hasNotificationEmail && <Grid item>
                <Typography variant="body2" color="textSecondary" style={{marginBottom: 24}}>
                    Don’t like waiting?<br />
                    Get your mastered tracks emailed to you when they’re ready
                </Typography>

                <TextField label="Email Address"
                           variant="outlined"
                           fullWidth
                           value={email}
                           onChange={e => setEmail(e.target.value)}
                           InputLabelProps={{shrink: true}}
                />

                <div style={{textAlign: "right", marginTop: 5}}>
                    {updateNotificationEmail.LoadingElement}
                    <Button color="primary" variant="outlined" onClick={() => {
                        updateNotificationEmail.callback({
                            jobId: props.jobId,
                            token: props.token,
                            email: email,
                        })
                    }}>
                        Email Masters To Me
                    </Button>
                </div>
            </Grid>}
        </Grid>
    )
}