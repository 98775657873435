import {createStyles, Grid, makeStyles} from "@material-ui/core";
import {Link} from "react-router-dom";
import React, {PropsWithChildren} from "react";
import { grey } from "@material-ui/core/colors";

const useStyles = makeStyles(theme => createStyles({
    header: {
        padding: 8,
        backgroundColor: theme.palette.primary.main,
    }
}));

export function Container(props: PropsWithChildren<{}>) {
    const styles = useStyles();
    return (
        <>
                <div className={styles.header}>
                    <Grid container spacing={3} justify="flex-end">
                        <Grid item>
                            <a style={{color: "white", textDecoration: "none"}} href="mailto:info@musicboxx.ca">Contact</a>
                        </Grid>
                        <Grid item>
                            <Link style={{color: "white", textDecoration: "none"}} to="/admin">Admin Panel</Link>
                        </Grid>
                    </Grid>
                </div>
            <Grid container justify="center" direction="column" alignItems="center" style={{flex: 1}}>
                <Grid item>
                    <img src="/logo-full-size-white.png" alt="logo" style={{
                        maxWidth: 400,
                        marginBottom: 40,
                    }} />
                </Grid>
                <Grid item>
                    {props.children}
                </Grid>
            </Grid>
            <div style={{flex: 0.2}} />
            <div style={{color: grey["500"], padding: 16, display: "flex", justifyContent: "flex-end"}}>
                Built with ♥ by&nbsp;<a style={{color: "inherit"}} href="https://blue-giraffe.ca">Blue Giraffe Software</a>
            </div>
        </>
    )
}