import React, {PropsWithChildren} from "react";
import {createStyles, ListItem, ListItemText, makeStyles} from "@material-ui/core";
import {Link, useLocation} from "react-router-dom";
import {grey, blue} from "@material-ui/core/colors";

const useStyles = makeStyles(theme => createStyles({
    link: {
        textDecoration: "none",
        color: "inherit",
        paddingLeft: 20,
        paddingRight: 20,
        backgroundColor: grey["200"],
        display: "block",

        "&:hover": {
            backgroundColor: grey["300"]
        }
    },
    linkText: {
        fontSize: "1rem", padding: 6, paddingLeft: 0, paddingRight: 0,
    }
}));

export function AdminContainer(props: PropsWithChildren<{}>) {
    const styles = useStyles();

    const loc = useLocation()
    const pod = loc.pathname.startsWith("/admin/podcasts")
    const mastering = loc.pathname.startsWith("/admin/mastering-token")

    return (
        <div style={{display: "flex", flexWrap: "nowrap", height: "100%", alignItems: "stretch"}}>
            <div style={{backgroundColor: grey["300"]}}>
                <div style={{backgroundColor: "black", padding: 20}}>
                    <img src="/logo-full-size-white.png" alt="logo" style={{
                        width: 200,
                    }} />
                </div>
                <Link to="/admin/mastering-token" className={styles.link}>
                    <ListItem>
                        <div className={styles.linkText} style={{
                            color: mastering ? blue["800"] : undefined,
                            fontWeight: mastering ? 700 : undefined,
                        }}>Mastering Token</div>
                    </ListItem>
                </Link>
                <Link to="/admin/podcasts" className={styles.link}>
                    <ListItem>
                        <div className={styles.linkText} style={{
                            color: pod ? blue["800"] : undefined,
                            fontWeight: pod ? 700 : undefined,
                        }}>Podcasts</div>
                    </ListItem>
                </Link>
            </div>
            <div style={{flex: 1, display: "flex", flexDirection: "column", overflow: "auto"}}>
                {props.children}
            </div>
        </div>
    )
}