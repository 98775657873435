import { Typography, Grid } from "@material-ui/core";
import React from "react";

export function Download(props: {
    token: string
}) {
    return (
        <Grid container direction="column" spacing={1}>
            <Grid item>
                <Typography variant="body1">
                    Your masters are ready! <br />
                    Download by clicking the link below
                </Typography>
            </Grid>
            <Grid item>
                <a href={"/download?f="+ props.token}>mastered-tracks.zip</a>
            </Grid>
        </Grid>
    )
}