import {Card} from "@material-ui/core";
import React, {PropsWithChildren} from "react";

export function BCard(props: PropsWithChildren<{
    maxWidth?: number
    style?: any;
}>) {
    return (
        <Card style={Object.assign({
            padding: 16,
            paddingTop: 32,
            paddingBottom: 32,
            maxWidth: props.maxWidth,
        }, props.style||{})}>{props.children}</Card>
    )
}