import {APIBase, ProgressCallback} from "nate-react-api-helpers";

export class API extends APIBase {
    constructor() {
        super();
    }

    validate(props: {
        token: string;
    }) {
        return this.fetcher.post<boolean>("/api/token/validate", props)
    }

    createUpload(props: {
        token: string;
        fileName: string;
    }) {
        return this.fetcher.post<CreateUploadResult>("/api/token/create-upload", props);
    }

    upload(url: string, file: File, onProgress: ProgressCallback) {
        return this.fetcher.fetchWithProgress("PUT", url, file, onProgress, {
            input: input => {
                input.params.credentials = "omit";
                return input;
            },
            preSend: xhr => {
                xhr.setRequestHeader("x-amz-acl", "private");
                xhr.setRequestHeader("Content-Type", file.type);
            }
        });
    }

    completeUpload(props: {
        token: string;
        jobId: number;
        email?: string | null;
    }) {
        return this.fetcher.post("/api/token/complete-upload", props);
    }

    getJobStatus(props: {
        jobId: number;
        token: string;
    }) {
        return this.fetcher.get<JobStatus>("/api/job/status", props)
    }

    setNotificationEmail(props: {
        jobId: number;
        token: string;
        email: string;
    }) {
        return this.fetcher.post("/api/job/set-email-notification", props);
    }

    generateToken() {
        return this.fetcher.post<{
            token: string;
            expiry: DateString;
        }>("/api/token", {});
    }

    ping() {
        return this.fetcher.get("/api/ping");
    }

    login(props: {
        username: string;
        password: string;
    }) {
        return this.fetcher.post("/api/auth/login", props);
    }

    listPodcasts() {
        return this.fetcher.get<Podcast[]>("/api/podcasts");
    }

    upsertPodcast(input: Partial<Podcast>) {
        return this.fetcher.post<Podcast>("/api/podcast", input);
    }

    getPodcast(input: {id: number}) {
        return this.fetcher.get<Podcast>("/api/podcast", input);
    }

    upsertPodcastFile(input: {
        size: number;
        name: string;
    }) {
        return this.fetcher.post<{
            file: number;
            preSignedUploadURL: string;
        }>("/api/podcast/part-upload", input);
    }

    completeUploadPodcastFile(input: {
        file: number;
    }) {
        return this.fetcher.post<{}>("/api/podcast/part-upload-complete", input);
    }

    createPodcastUpload(input: {
        key: string;
    }) {
        return this.fetcher.post<{token: string}>("/api/podcast/create-upload", input);
    }
}

export type Podcast = {
    id: number;
    name: string;
    description: string;
    archived: boolean;
    createdAt: DateString;

    introFile: number | null;
    introFileName: string;
    introFileStatus: string;
    introOverlapMs: number;

    outroFile: number | null;
    outroFileName: string;
    outroFileStatus: string;
    outroOverlapMs: number;

    clientUploadKey: string;
    masterCount: number;
}

type DateString = string;

export type JobStatus = {
    progress: number;
    status: string;
    done: boolean;
    errorMessage?: string;
    downloadToken?: string;
}

export type CreateUploadResult = {
    jobId: number;
    uploadUrl: string;
}

export const api = new API();