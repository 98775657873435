import {Grid, Typography, IconButton} from "@material-ui/core";
import {BCard} from "../BCard";
import React, {useState} from "react";
import moment from "moment";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { useAsyncAction} from "nate-react-api-helpers";
import {api} from "../API";
import {Container} from "../Container";
import InputAdornment from "@material-ui/core/InputAdornment";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import {showSuccessSnack} from "nate-react-api-helpers/lib/components";
import {AdminContainer} from "./AdminContainer";


export function MasteringToken() {

    const [url, setUrl] = useState("");
    const generate = useAsyncAction(async () => {
        const {token} = await api.generateToken();
        setUrl("https://" + window.location.host + "/master/" + token);
    }, []);

    return (
        <AdminContainer>
            <div style={{display: "flex", alignItems: "center", justifyContent: "center", flex: 1}}>
                <BCard style={{maxWidth: 600}}>
                    <Grid container direction="column" spacing={2}>
                        <Grid item>
                            <Typography variant="h6">Create a Self-Serve Mastering Token</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2" color="textSecondary">
                                The unique URL generated below will give the customer access to make 1 conversion. The link will expire in 7 days (
                                {moment().add(7, "days").format("dddd, MMMM Do")})
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item>
                                    <Button variant="contained" color="primary" onClick={() => generate.callback(null)}>
                                        Generate URL
                                    </Button>
                                </Grid>
                                <Grid item>
                                    {generate.LoadingElement}
                                </Grid>
                            </Grid>
                        </Grid>
                        {url && <Grid item>
                            <TextField
                                variant="outlined"
                                value={url}
                                fullWidth
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => {
                                                navigator.clipboard.writeText(url);
                                                showSuccessSnack("URL copied to clipboard");
                                            }}>
                                                <FileCopyIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>}
                    </Grid>
                </BCard>
            </div>
        </AdminContainer>
    )
}