import React, {useState} from "react";
import {Button, Dialog, LinearProgress} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import {useAsyncAction} from "nate-react-api-helpers";
import {api} from "../../API";
import DialogActions from "@material-ui/core/DialogActions";

export function UploadIntroOutro(props: {
    name: string;
    existingFileName: string | null;

    onCancel(): void;
    onDone(info: null | {
        file: number;
        fileName: string;
    }): void;
}) {

    const [fileInput, setFileInput] = useState<HTMLInputElement|null>();

    const [progress, setProgress] = useState<number|null>(null);

    const upload = useAsyncAction(async (input: {
        file: File;
    }) => {
        const info = await api.upsertPodcastFile({
            size: input.file.size,
            name: input.file.name,
        })

        await api.upload(info.preSignedUploadURL, input.file, prog => {
            setProgress(prog.loaded / prog.total * 0.9)
        })

        setProgress(90)

        await api.completeUploadPodcastFile({
            file: info.file,
        })

        setProgress(null);

        return {
            file: info.file,
            fileName: input.file.name,
        }
    }, []);

    return (
        <Dialog open>
            <DialogTitle>Upload {props.name}</DialogTitle>
            <DialogContent>
                <div style={{height: 0, width: 0, position: "relative"}}>
                    <input type="file" accept="audio/*"
                           style={{position: "absolute", top: 0, left: -1000}}
                           ref={setFileInput}
                    />
                </div>
                <div style={{marginTop: 8}} />
                {upload.result ? <>
                    {upload.result.fileName} has been uploaded
                </> : <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        if(!fileInput) return;
                        fileInput.click();
                        fileInput.onchange = () => {
                            if(!fileInput.files || fileInput.files.length === 0) return;
                            const file = fileInput.files.item(0)
                            if(!file) return;

                            upload.callback({
                                file: file,
                            });
                        }

                    }}
                >Choose your Track</Button>}

                {progress !== null && <LinearProgress value={progress} style={{width: "100%"}} />}
            </DialogContent>
            <DialogActions>
                <Button disabled={!props.existingFileName} style={{color: "red"}} onClick={() => {
                    props.onDone(null);
                }}>
                    Remove Track
                </Button>
                <div style={{flex: 1}} />
                {upload.LoadingElement}
                <Button onClick={props.onCancel}>Cancel</Button>
                <Button variant="contained" color="primary" disabled={!upload.result} onClick={()=> {
                    props.onDone(upload.result)
                }}>Update</Button>
            </DialogActions>
        </Dialog>
    )
}