import {AdminContainer} from "../AdminContainer";
import React, {useState} from "react";
import {BCard} from "../../BCard";
import {Button, IconButton, TextField} from "@material-ui/core";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {Link, useParams} from "react-router-dom";
import {useAsync2, useAsyncAction} from "nate-react-api-helpers";
import {api} from "../../API";
import {PodcastDetailForm} from "./PodcastDetailForm";

export function PodcastDetailPage() {

    const search = useParams<{id: string}>();
    const id = parseInt(search["id"] || "") || 0;
    const data = useAsync2((input) => {
        return api.getPodcast(input)
    }, {id: id}, [id]);

    return (
        <AdminContainer>
            <div style={{flex: 1, display: "flex", alignItems: "center", justifyContent: "center"}}>
                <BCard style={{width: 600}}>
                    <div>
                        <Link to="/admin/podcasts">
                            <Button startIcon={<ArrowBackIcon />}>
                                Back
                            </Button>
                        </Link>
                    </div>

                    {data.LoadingOrErrorElement}
                    {data.result && <PodcastDetailForm value={data.result} onReload={data.reload} />}
                </BCard>
            </div>
        </AdminContainer>
    )
}