import React, {useState} from "react";
import {Dialog, Grid} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {useAsyncAction, useAuthenticated} from "nate-react-api-helpers";
import {api} from "./API";

export function RequireLogin() {

    const auth = useAuthenticated();
    const [username, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const login = useAsyncAction(async (input) => {
        await api.login(input);
        window.location.reload();
    }, []);

    if(auth.authenticated) return null;

    return <Dialog open={true} maxWidth="xs" fullWidth>
        <form onSubmit={e => {
            e.preventDefault();
            login.callback({username, password});
        }}>
            <DialogTitle></DialogTitle>
            <DialogContent>
                <Grid container spacing={2} direction="column">
                    <Grid item>
                        <TextField label="Username" value={username} onChange={e => setUserName(e.target.value)} variant="outlined" fullWidth />
                    </Grid>
                    <Grid item>
                        <TextField label="Password" onChange={e => setPassword(e.target.value)} type="password" variant="outlined" fullWidth />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justify="flex-end" alignItems="center" spacing={1}>
                    <Grid item>
                        {login.LoadingElement}
                    </Grid>
                    <Grid item>
                        <Button variant="contained" type="submit" color="primary">Login</Button>
                    </Grid>
                </Grid>

            </DialogActions>
        </form>
    </Dialog>
}