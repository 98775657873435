import {Container} from "../Container";
import {useAsyncAction} from "nate-react-api-helpers";
import {useHistory, useParams} from "react-router-dom";
import {useEffect} from "react";
import {api} from "../API";

export function PodcastPage() {

    const params = useParams<{key: string}>();
    const history = useHistory()

    const load = useAsyncAction(async (input) => {
        const result = await api.createPodcastUpload(input)
        history.push("/master/" + result.token)
    }, []);

    const tk = params.key;
    useEffect(() => {
        load.callback({
            key: tk,
        })
    }, [tk]);

    return (
        <Container>
            {load.LoadingElement}
        </Container>
    )
}