import {Button, Grid, TextField} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {useAsyncAction} from "nate-react-api-helpers";
import {api} from "../API";
import { useRouteMatch } from "react-router-dom";
import {green} from "@material-ui/core/colors";
import CheckIcon from '@material-ui/icons/Check';

export function AccessCode(props: {
    onUpload(input: {file: File, token: string}): void;
}) {
    const paramsToken = useRouteMatch<{token?: string}>("/master/:token")?.params.token;

    const [token, setToken] = useState("");
    const [tokenIsValid, setTokenIsValid] = useState(false);
    const [tokenChangedSinceValidation, setTokenChangedSinceValidation] = useState(false);
    const check = useAsyncAction(async (input: {token: string, callback?: () => void}) => {
        setTokenChangedSinceValidation(false);
        await api.validate({token: input.token});
        setTokenIsValid(true);
        if(input.callback) input.callback();
    }, []);

    useEffect(() => {
        setTokenIsValid(false);
        setTokenChangedSinceValidation(true);
    }, [token])

    const [fileInput, setFileInput] = useState<HTMLInputElement|null>(null);

    const checker = check.callback;
    useEffect(() => {
        if(!paramsToken) return;
        setToken(paramsToken);
        checker({token: paramsToken});
    }, [paramsToken, checker]);

    const onUpload = props.onUpload;

    useEffect(() => {
        if(check.error) return;
        if(!fileInput) return;
        if(!token) return;

        const callback = function() {
            if(!fileInput.files || fileInput.files.length === 0) {
                return;
            }

            const file = fileInput.files[0];
            onUpload({file, token});
        }

        fileInput.addEventListener("change", callback);
        return () => {
            fileInput.removeEventListener("change", callback);
        }
    }, [fileInput, check.error, token, onUpload]);

    const isError = !tokenChangedSinceValidation && !!check.error;

    return (
        <Grid container direction="column" spacing={2}>
            <Grid item>
                <TextField
                    variant="filled"
                    label="Access Code"
                    InputLabelProps={{shrink: true}}
                    fullWidth
                    error={isError}
                    value={token}
                    onChange={e => setToken(e.target.value)}
                    helperText={isError ? "Invalid access code" : tokenIsValid ?
                        <div style={{color: green["700"], display: "flex", alignItems: "center"}}>
                            <CheckIcon fontSize="inherit" />
                            <span style={{marginLeft: 5}}>
                                Looks good!
                            </span>
                        </div> : ""}
                />
            </Grid>
            <Grid item>
                <div style={{height: 0, width: 0, position: "relative"}}>
                    <input type="file" accept="audio/*"
                           style={{position: "absolute", top: 0, left: -1000}}
                           ref={setFileInput}
                    />
                </div>
                <div style={{marginTop: 16}} />
                <Button
                    disabled={!token}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        check.callback({
                            token: token,
                            callback: () => {
                                if(!fileInput) throw new Error("missing file input");
                                fileInput.click();
                            },
                        });
                    }}
                >Choose your Track</Button>
            </Grid>
        </Grid>
    )
}