import {Button, Dialog, Divider, IconButton, TextField} from "@material-ui/core";
import React, {useEffect, useRef, useState} from "react";
import {useAsyncAction} from "nate-react-api-helpers";
import {api, Podcast} from "../../API";
import {IntroOutroRow} from "./IntroOutroRow";
import {grey} from "@material-ui/core/colors";
import InputAdornment from "@material-ui/core/InputAdornment";
import {showSuccessSnack} from "nate-react-api-helpers/lib/components";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import {useHistory} from "react-router-dom";

export function PodcastDetailForm(props: {
    value: Podcast;
    onReload(): void;
}) {
    const [name, setName] = useState(props.value.name);
    const [description, setDescription] = useState(props.value.description);
    const [introName, setIntroName] = useState(props.value.introFileName);
    const [outroName, setOutroName] = useState(props.value.outroFileName);
    const [introOverlapMs, setIntroOverlapMs] = useState(props.value.introOverlapMs.toString());
    const [outroOverlapMs, setOutroOverlapMs] = useState(props.value.outroOverlapMs.toString());
    const [introFile, setIntroFile] = useState(props.value.introFile)
    const [outroFile, setOutroFile] = useState(props.value.outroFile)

    useEffect(() => {
        setName(props.value.name);
        setDescription(props.value.description);
        setIntroName(props.value.introFileName);
        setOutroName(props.value.outroFileName);
        setIntroOverlapMs(props.value.introOverlapMs.toString());
        setOutroOverlapMs(props.value.outroOverlapMs.toString());
        setIntroFile(props.value.introFile)
        setOutroFile(props.value.outroFile)
    }, [props.value]);

    const onReloadRef = useRef(props.onReload);
    onReloadRef.current = props.onReload;
    const history = useHistory();

    const save = useAsyncAction(async (input) => {
        await api.upsertPodcast(input)
        onReloadRef.current();

        if(input.archived) {
            history.go(-1);
        }
    }, [])
    const url = "https://mastering.musicboxx.ca/podcast/" + props.value.clientUploadKey
    const [showDelete, setShowDelete] = useState(false);

    return (
        <>
            <form onSubmit={e => {
                e.preventDefault()
                save.callback(Object.assign({}, props.value, {
                    name,
                    description,
                    introOverlapMs: parseInt(introOverlapMs || "0"),
                    outroOverlapMs: parseInt(outroOverlapMs || "0"),
                    introFile,
                    outroFile,
                }))
            }}>
                <div style={{height: 8}} />
                <TextField label="Podcast Name" variant="outlined" fullWidth value={name} onChange={e => setName(e.target.value)} />
                <div style={{height: 8}} />

                <TextField label="Description" variant="outlined" fullWidth multiline={true} rows={3} value={description} onChange={e => setDescription(e.target.value)} />

                <div style={{height: 8}} />
                <div style={{backgroundColor: grey["200"], borderRadius: 4, border: "1px solid " + grey["300"]}}>
                    <IntroOutroRow setFile={setIntroFile} title="Intro" overlapMs={introOverlapMs} setOverlapMs={setIntroOverlapMs} fileName={introName} />
                    <Divider />
                    <IntroOutroRow setFile={setOutroFile} title="Outro" overlapMs={outroOverlapMs} setOverlapMs={setOutroOverlapMs} fileName={outroName} />
                </div>

                <div style={{height: 16}} />

                <TextField fullWidth disabled={true} label="Client Upload Link" value={url} InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={() => {
                                navigator.clipboard.writeText(url);
                                showSuccessSnack("URL copied to clipboard");
                            }}>
                                <FileCopyIcon />
                            </IconButton>
                        </InputAdornment>
                    ),
                }} />

                <div style={{height: 16}} />

                {showDelete && <Dialog open>
                    <DialogTitle>Confirm</DialogTitle>
                    <DialogContent>
                        Are you sure you want to delete this?
                    </DialogContent>
                    <DialogActions>
                        {save.LoadingElement}
                        <Button onClick={() => setShowDelete(false)}>Cancel</Button>
                        <Button style={{color: "red"}} onClick={() => {
                            save.callback(Object.assign({}, props.value, {
                                archived: true
                            }))
                        }}>Delete</Button>
                    </DialogActions>
                </Dialog>}

                <div style={{display: "flex", justifyContent: "flex-end"}}>
                    <Button style={{color: "red"}} onClick={() => {
                        setShowDelete(true);
                    }}>
                        Delete Podcast
                    </Button>
                    <div style={{flex: 1}} />
                    {save.LoadingElement}
                    <Button>Cancel</Button>
                    <Button variant="contained" color="primary" type="submit">Save</Button>
                </div>
            </form>
        </>
    )
}
