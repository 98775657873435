import {Button, TextField} from "@material-ui/core";
import React, {useState} from "react";
import {UploadIntroOutro} from "./UploadIntroOutro";

export function IntroOutroRow(props: {
    title: string;
    fileName: string;

    setFile(file: number|null): void;

    overlapMs: string;
    setOverlapMs(value: string): void;
}) {

    const [showUpload, setShowUpload] = useState(false);
    const [fileName, setFileName] = useState(props.fileName);

    return (
        <div style={{display: "flex", flexDirection: "column", flexWrap: "nowrap", alignItems: "stretch", padding: 10, paddingLeft: 10, paddingRight: 10, justifyContent: "space-between"}}>
            <div style={{paddingRight: 15, padding: 2, fontSize: "0.7rem"}}>
                {props.title.toUpperCase()}
            </div>

            {showUpload && <UploadIntroOutro existingFileName={fileName || null} name={props.title} onCancel={() => setShowUpload(false)} onDone={(info) => {
                props.setFile(info?.file||null);
                setFileName(info?.fileName || "");
                setShowUpload(false);
            }} />}

            <div style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                flex: 1,
            }}>
                <div style={{display: "flex", alignItems: "center"}}>
                    <div style={{
                        flex: 1,
                        fontSize: "1rem",
                    }}>
                        {fileName ? <>File: {fileName}</> : <i>None</i>}
                    </div>

                    <div style={{width: 8}} />

                    <Button color="primary" size="small" onClick={() => {
                        setShowUpload(true);
                    }}>Set Track</Button>
                </div>
                <div>
                    <TextField
                        label="Overlap Ms"
                        value={props.overlapMs}
                        style={{width: 100}}
                        variant="outlined"
                        size="small"
                        onChange={e => props.setOverlapMs(numbersOnly(e.target.value))}
                    />
                </div>

            </div>
        </div>
    )
}

function numbersOnly(value: string) {
    return value.replace(/[^0-9]+/, "")
}